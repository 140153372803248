<div class="flex flex-col items-start justify-start">
  <div class="w-full">
    <div class="flex items-center content-center gap-4 flex-wrap">
      <div
        class="rounded-full h-12 w-12 relative"
        (mouseleave)="showEdit = false"
        (mouseenter)="showEdit = true"
      >
        <img [src]="profilePic" onerror="this.src='assets/images/avatar.jpg'" class="rounded-full h-12 w-12" />
        <label
          for="profile"
          *ngIf="showEdit"
          (click)="input.click()"
          class="rounded-full cursor-pointer h-12 w-12 bg-[#1c1c1c36] flex items-center justify-center absolute top-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="white"
            viewBox="0 0 24 24"
            width="20"
            height="20"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
            />
          </svg>

          <input
            #input
            type="file"
            id="profile"
            accept="image/png, image/jpeg, image/jpg"
            (change)="fileEvent($event)"
            hidden
          />
        </label>
      </div>
      <div class="flex flex-col items-start justify-center">
        <span class="text-lg Poppins-Bold">{{
          userDetails?.fullName | empty : "-"
        }}</span>
        <span class="text-sm">{{ userDetails?.email | empty : "-" }}</span>
      </div>
    </div>
    <form [formGroup]="personalInformationForm">
      <div class="flex gap-4 items-center justify-start py-8">
        <div class="flex items-center">
          <label for="fullName" class="text-sm text-[#1C1C1C66]">{{
            "Full Name" | translate
          }}</label>
        </div>
        <div class="">
          <input
            id="fullName"
            formControlName="fullName"
            type="text"
            class="new-input"
            placeholder="{{ 'Enter Full Name' | translate }}"
            (ngModelChange)="onChangeObs.next()"
          />
        </div>
      </div>
    </form>
  </div>
  <div class="flex gap-4 w-full">
    <div class="flex flex-col w-1/2">
      <app-passwords> </app-passwords>
      <app-two-factor class="mt-4"> </app-two-factor>
    </div>
    <div class="flex flex-col w-1/2">
      <app-emails> </app-emails>
      <app-email-config class="mt-4"></app-email-config>
      <app-language-config class="mt-4"></app-language-config>
      <app-delete-account class="mt-4 flex justify-end"></app-delete-account>
    </div>
  </div>
</div>

<app-image-cropper
  [isOpen]="openModal"
  [imageCropperConfig]="imageCropperConfig"
  (closeImage)="openModal = false"
  (base64Image)="saveImage($event)"
>
</app-image-cropper>
