<div class="flex justify-center flex-col px-14 py-11 rounded-xl bg-white">
  <div class="flex items-center justify-between">
    <h1 class="text-2xl Poppins-Medium inline-flex items-center">
      #{{ invoiceData?.invoiceNumber }}
    </h1>
    <app-button
      appAccessControl
      moduleType="sales"
      accessType="edit"
      type="primary"
      buttonText="{{ 'Create another Invoice' | translate }}"
      size="large"
      routerLink="../create-cash-invoice"
    ></app-button>
  </div>
  <div class="">
    <div class="flex justify-between p-6 my-3">
      <div class="flex space-x-8 rtl:space-x-reverse">
        <div>
          <p class="Poppins-Medium text-lg">{{ "Status" | translate }}</p>
          <app-button
            [customClass]="
              (invoiceData?.status.primaryStatus === 'paid'
                ? 'bg-green-400'
                : 'bg-red-800') + ' cursor-auto'
            "
            type="primary"
          >
            {{ invoiceData?.status.primaryStatus | titlecase | translate }}
          </app-button>
        </div>
        <div>
          <p class="Poppins-Medium text-lg">{{ "Customer" | translate }}</p>
          <h3 class="Poppins-Bold text-xl">
            {{
              invoiceData?.customerDetails?.customerId?.customerName
                ? invoiceData?.customerDetails?.customerId?.customerName
                : invoiceData?.customerDetails?.customerId?.mobileNumber
            }}
          </h3>
        </div>
      </div>
      <div class="flex space-x-8 rtl:space-x-reverse">
        <div>
          <p class="Poppins-Medium text-lg">{{ "Amount due" | translate }}</p>
          <h3 class="Poppins-Bold text-xl">
            {{
              amountDue
                | currency
                  : currencyDetails?.currency
                  : "symbol-narrow"
                  : number
            }}
          </h3>
        </div>
        <div>
          <p class="Poppins-Medium text-lg">{{ "Due on" | translate }}</p>
          <h3 class="Poppins-Bold text-xl">
            {{ invoiceData?.dueDate | date : "longDate" }}
          </h3>
        </div>
      </div>
    </div>
    <div class="flex items-center new-design-round-border p-6 my-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-6 h-6 me-4"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill="#1c1c1c"
      >
        <path
          d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"
        />
      </svg>
      <div>
        <p class="Poppins-Medium text-lg">{{ "Create" | translate }}</p>
        <h4 class="Poppins-Medium text-lg">
          {{ "Created:" | translate }}
          {{ invoiceData?.createdAt | date : "longDate" }}
        </h4>
      </div>
      <div class="flex gap-x-2 ms-auto">
        <app-button
          (handleClick)="downloadPdf('regular')"
          type="primary"
          buttonText="{{ 'Download Regular PDF' | translate }}"
        ></app-button>
        <app-button
          (handleClick)="downloadPdf('pos')"
          type="primary"
          buttonText="{{ 'Download POS PDF' | translate }}"
        ></app-button>
      </div>
    </div>
    <div
      [ngClass]="
        invoiceData?.status.primaryStatus !== 'draft' ? '' : 'opacity-30'
      "
      class="flex items-center new-design-round-border p-6 my-3"
    >
      <svg
        height="24"
        class="w-6 h-6 me-4"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.984 2.422a.703.703 0 0 0-.938-.805L.452 10.242a.703.703 0 0 0-.003 1.313l6.348 2.451v7.721a.703.703 0 0 0 1.331.316l2.625-5.209 6.406 4.754c.39.289.95.105 1.092-.359 5.981-19.61 5.722-18.757 5.733-18.807m-5.577 2.471L7.4 12.732l-4.735-1.829zM8.203 13.886l9.594-6.832c-8.255 8.709-7.824 8.251-7.86 8.299-.053.072.093-.209-1.734 3.416zm8.993 5.979-5.639-4.185L21.753 4.924z"
        />
      </svg>
      <div>
        <p class="Poppins-Medium text-lg">{{ "Send" | translate }}</p>
        <span class="Poppins-Medium text-lg me-2"
          >{{ "Status:" | translate }}
          {{ invoiceData?.status.secondaryStatus }}</span
        >
      </div>
      <div
        *ngIf="invoiceData?.status.primaryStatus !== 'draft'"
        class="ms-auto gap-x-2 flex items-center"
      >
        <app-button
          (handleClick)="emailModal = true; fetchSendInvoiceEmail()"
          type="primary"
          [buttonText]="'Send Invoice' | translate"
        ></app-button>
        <app-button
          appAccessControl
          moduleType="sales"
          accessType="edit"
          (handleClick)="markAsSent()"
          type="third"
          [buttonText]="'Mark as sent' | translate"
          *ngIf="invoiceData?.status?.secondaryStatus !== 'Sent'"
        ></app-button>
        <app-button
          (handleClick)="shareURLModal = true"
          type="third"
          [buttonText]="'Get share link' | translate"
        ></app-button>
      </div>
    </div>

    <div
      *ngIf="invoiceData?.paymentHistory?.length > 0"
      class="new-design-round-border p-6 my-3"
    >
      <div class="mt-2" *ngIf="invoiceData?.paymentHistory?.length > 0">
        <table class="w-full">
          <thead class="text-[#1C1C1C66] border-[#1C1C1C33] border-b">
            <tr>
              <td class="px-4 py-2">{{ "Date" | translate }}</td>
              <td class="px-4 py-2">{{ "Amount" | translate }}</td>
              <td class="px-4 py-2">{{ "Payment Method" | translate }}</td>
              <td class="px-4 py-2">{{ "Memo" | translate }}</td>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let payment of invoiceData?.paymentHistory
                  | paginate : { itemsPerPage: 5, currentPage: page }
              "
            >
              <td class="pl-3 py-4">
                {{ payment.paymentDate | date : "dd-MM-yyyy" }}
              </td>
              <td class="pl-3 py-4">
                {{
                  payment.amount
                    | currency
                      : currencyDetails?.currency
                      : "symbol-narrow"
                      : number
                }}
              </td>
              <td class="pl-3 py-4">{{ payment.paymentMethod }}</td>
              <td class="pl-3 py-4">{{ payment.memo }}</td>
            </tr>
          </tbody>
        </table>
        <pagination-controls
          autoHide="true"
          (pageChange)="page = $event"
        ></pagination-controls>
      </div>
    </div>
    <div class="new-design-round-border p-6 my-3">
      <div class="flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6 me-4"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#1c1c1c"
        >
          <path
            d="M880-720v480q0 33-23.5 56.5T800-160H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720Zm-720 80h640v-80H160v80Zm0 160v240h640v-240H160Zm0 240v-480 480Z"
          />
        </svg>

        <div>
          <p class="Poppins-Medium text-lg">{{ "Issue Refund" | translate }}</p>
          <h4 class="Poppins-Medium text-lg">
            {{ "Total Refunded Amount:" | translate }}
            {{
              refundAmount
                | currency
                  : currencyDetails?.currency
                  : "symbol-narrow"
                  : number
            }}
          </h4>
        </div>
        <app-button
          appSubscriptionCheck
          featureToCheck="transactionLimit"
          appAccessControl
          moduleType="sales"
          class="ms-auto"
          accessType="edit"
          (handleClick)="openRefundPayment()"
          type="third"
          [buttonText]="'Record Refund' | translate"
        ></app-button>
      </div>
      <div class="mt-2" *ngIf="invoiceData?.refundHistory?.length > 0">
        <table class="w-full">
          <tr>
            <td class="bg-gray-100 Poppins-Bold p-2">
              {{ "Date" | translate }}
            </td>
            <td class="bg-gray-100 Poppins-Bold">{{ "Amount" | translate }}</td>
            <td class="bg-gray-100 Poppins-Bold">
              {{ "Payment Method" | translate }}
            </td>
            <td class="bg-gray-100 Poppins-Bold">{{ "Memo" | translate }}</td>
            <td class="bg-gray-100 Poppins-Bold">
              {{ "Actions" | translate }}
            </td>
          </tr>
          <tr
            *ngFor="
              let refundPayment of invoiceData?.refundHistory
                | paginate : { itemsPerPage: 5, currentPage: page }
            "
          >
            <td class="p-2">
              {{ refundPayment?.refundDate | date : "dd-MM-yyyy" }}
            </td>
            <td>
              {{
                refundPayment?.amount
                  | currency
                    : currencyDetails?.currency
                    : "symbol-narrow"
                    : number
              }}
            </td>
            <td>{{ refundPayment?.paymentMethod }}</td>
            <td>{{ refundPayment?.memo }}</td>
            <td>
              <div
                *ngIf="refundPayment?.paymentMethod !== 'paypal'"
                appAccessControl
                moduleType="sales"
                accessType="edit"
                class="flex item-center"
              >
                <app-tooltip
                  customClass="mb-8"
                  class="me-2"
                  tooltipName="{{ 'Refund' | translate }}"
                >
                  <svg
                    [routerLink]="[
                      '/sales/invoices/view-receipt',
                      refundPayment.recieptId
                    ]"
                    ro
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    />
                  </svg>
                </app-tooltip>
              </div>
            </td>
          </tr>
        </table>
        <pagination-controls
          autoHide="true"
          (pageChange)="page = $event"
        ></pagination-controls>
      </div>
    </div>
    <div class="new-design-round-border my-4 p-6 flex flex-col justify-between">
      <app-file-upload
        appAccessControl
        type="compact"
        tableType="new"
        moduleType="storage"
        accessType="view"
        *ngIf="showFiles"
        [addedFiles]="addedFiles"
        (onUpload)="uploadFiles()"
        (emitter)="saveFiles($event)"
      ></app-file-upload>
    </div>
    <div>
      <app-template-one
        *ngIf="customizationSettings?.selectedTemplate === 'modern'"
        [invoiceData]="invoiceData2"
      ></app-template-one>
      <app-template-two
        *ngIf="customizationSettings?.selectedTemplate === 'classic'"
        [invoiceData]="invoiceData2"
      ></app-template-two>
      <app-template-three
        *ngIf="customizationSettings?.selectedTemplate === 'contemporary'"
        [invoiceData]="invoiceData2"
      ></app-template-three>
      <app-template-four
        *ngIf="customizationSettings?.selectedTemplate === 'templateOption1'"
        [invoiceData]="invoiceData"
      ></app-template-four>
      <app-template-five
        *ngIf="customizationSettings?.selectedTemplate === 'templateOption2'"
        [invoiceData]="invoiceData"
      ></app-template-five>
      <app-template-six
        *ngIf="customizationSettings?.selectedTemplate === 'jordan'"
        [invoiceData]="invoiceData"
      ></app-template-six>
      <app-template-seven
        *ngIf="customizationSettings?.selectedTemplate === 'saudi'"
        [invoiceData]="invoiceData"
      ></app-template-seven>
    </div>
  </div>
</div>

<app-modal-container [isOpen]="shareURLModal">
  <div header class="Poppins-Bold">
    {{ "Estimate Shareable link" | translate }}
  </div>
  <div content class="flex items-center me-2">
    <div class="border rounded-xl flex items-center ps-2">
      <a
        [href]="shareURL"
        class="max-w-[456px] overflow-hidden whitespace-nowrap overflow-ellipsis"
      >
        {{ shareURL }}
      </a>
      <app-button
        type="primary"
        [buttonText]="copyButtonText"
        (handleClick)="copyLink()"
      ></app-button>
    </div>
  </div>
  <div footer>
    <app-button
      type="third"
      buttonText="{{ 'Close' | translate }}"
      (handleClick)="shareURLModal = false"
    ></app-button>
  </div>
</app-modal-container>

<app-modal-container [isOpen]="emailModal">
  <div header class="Poppins-Bold">
    {{ "Share estimate via email" | translate }}
  </div>
  <div content class="flex flex-col" style="overflow-y: auto">
    <label for="">{{ "Email" | translate }}</label>
    <input
      [formControl]="email"
      type="text"
      class="w-full rounded-xl bg-gray-100 border-0 p-3"
    />
    <div
      *ngIf="email.getError('required') && email.dirty"
      class="text-sm text-red-400 ms-2"
    >
      {{ "Email is required" | translate }}
    </div>
    <div
      *ngIf="email.getError('pattern') && email.dirty"
      class="text-sm text-red-400 ms-2"
    >
      {{ "Email must be in correct format" | translate }}
    </div>
    <div *ngIf="error" class="text-red-500 ms-2">{{ error }}</div>
    <div *ngIf="response" class="text-green-500 ms-2">{{ response }}</div>
  </div>
  <div footer class="flex gap-x-2">
    <app-button
      type="primary"
      buttonText="{{ 'Send' | translate }}"
      (handleClick)="sendEmail()"
    ></app-button>
    <app-button
      type="third"
      buttonText="{{ 'Cancel' | translate }}"
      (handleClick)="emailModal = false"
    ></app-button>
  </div>
</app-modal-container>

<app-modal-container [isOpen]="confirmDeleteModal">
  <div header>
    {{ "Delete Payment Record" | translate }}
  </div>
  <div content>
    <p>{{ "Delete payment confirmation" | translate }}</p>
    <p>{{ "Delete payment warning" | translate }}</p>
  </div>
  <div footer class="flex gap-x-2">
    <app-button
      type="third"
      buttonText="{{ 'Cancel' | translate }}"
      (handleClick)="confirmDeleteModal = false"
    ></app-button>
  </div>
</app-modal-container>

<!-- Record Refund Modal Start -->

<app-modal-container [isOpen]="recordRefundModal">
  <div class="Poppins-Bold" header>{{ "Refund Record" | translate }}</div>
  <div content [formGroup]="refundForm" class="space-y-2 flex flex-col">
    <div class="mt-2">
      <table class="w-full text-xs text-left rtl:text-right">
        <thead class="text-[#1C1C1C66] border-[#1C1C1C33] border-b">
          <tr>
            <td class="px-4 py-2">{{ tableFields.items | translate }}</td>
            <td class="px-4 py-2 w-1/12">
              {{ tableFields.units | translate }}
            </td>
            <td class="px-4 py-2 w-2/12">
              {{ tableFields.price | translate }}
            </td>
            <td class="px-4 py-2 w-3/12">{{ "Tax:" | translate }}</td>
            <td class="px-4 py-2">{{ tableFields.amount | translate }}</td>
            <td class="px-4 py-2"></td>
          </tr>
        </thead>
        <ng-container formArrayName="items">
          <ng-container *ngFor="let control of items.controls; let i = index">
            <tr [formGroup]="control" class="">
              <td class="w-3/12 pl-4 py-4">
                <ng-select
                  [items]="itemList"
                  class="new-dropdown"
                  bindLabel="item"
                  bindValue="item"
                  formControlName="item"
                  [clearable]="false"
                  placeholder="{{ 'Select one' | translate }}"
                  (change)="changeEvent($event, i)"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="px-2 py-1">
                      <div
                        class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                      >
                        {{ item.item }}
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </td>
              <td class="pl-4 py-4 w-1/12">
                <fieldset>
                  <input
                    id="quantity"
                    formControlName="unit"
                    type="number"
                    min="0"
                    class="w-full p-3 rounded-xl bg-input border-0"
                  />
                </fieldset>
              </td>
              <td class="pl-4 py-4 w-2/12">
                <fieldset disabled="true">
                  <input
                    id="price"
                    formControlName="price"
                    type="number"
                    min="0"
                    class="w-full p-3 rounded-xl bg-input border-0"
                  />
                </fieldset>
              </td>
              <td class="pl-4 py-4 w-3/12">
                <fieldset disabled="true">
                  <input
                    formControlName="tax"
                    type="text"
                    value="{{
                      items.controls[i].get('tax').value != null
                        ? items.controls[i].get('tax').value[0]?.taxName
                        : (null | json)
                    }}"
                    class="w-full p-3 rounded-xl bg-input border-0"
                  />
                </fieldset>
              </td>
              <td class="pl-4 py-4">
                <p>
                  {{
                    items.controls[i].get("unit").value *
                      items.controls[i].get("price").value
                      | currency
                        : currencyDetails?.currency
                        : "symbol-narrow"
                        : number
                  }}
                </p>
              </td>
              <td class="w-1/12">
                <svg
                  (click)="removeItem(i)"
                  xmlns="http://www.w3.org/2000/svg"
                  name="delete"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="mx-auto h-5 w-5 text-black-500"
                >
                  <path
                    _ngcontent-hsm-c58=""
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  ></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td colspan="1"></td>
              <td>
                <p
                  class="text-red-500"
                  *ngIf="control.get('unit').hasError('min')"
                >
                  {{ "Atleast 1 unit is required" }}
                </p>
                <p
                  class="text-red-500"
                  *ngIf="control.get('unit').hasError('max')"
                >
                  {{ "limit Reached" }}
                </p>
              </td>
              <td colspan="4"></td>
            </tr>
          </ng-container>
          <ng-container *ngIf="items.controls.length === 0">
            <tr>
              <td colspan="8" class="text-center p-2">
                {{ "No items added" | translate }}
              </td>
            </tr>
          </ng-container>
        </ng-container>
        <tr>
          <td vAlign="top" colspan="4">
            <div class="ps-2 text-black">
              <button
                (click)="addNewItem()"
                id="add"
                class="flex items-center space-x-4"
              >
                <span
                  class="p-2 rounded-sm border border-[#1C1C1C0D] bg-[#f6f6f6c9] flex gap-2 me-2"
                >
                  <img
                    src="assets/images/delete-icon.svg"
                    style="transform: rotate(45deg)"
                    alt=""
                  />
                  {{ "Add a Line" | translate }}
                </span>
              </button>
            </div>
          </td>
        </tr>
      </table>
      <!-- <pagination-controls autoHid e="true" (pageChange)="page = $event"></pagination-controls> -->
    </div>
    <div class="flex flex-col gap-[10px]">
      <label class="Poppins-Medium text-base" for="amount">{{
        "Amount" | translate
      }}</label>
      <input
        formControlName="amount"
        id="amount"
        type="number"
        [value]="totalAmount"
        class="w-full bg-input rounded-lg border-0 h-11 me-2"
        disabled
      />
      <div class="text-red-500 text-sm ms-2" *ngIf="invalidRefundAmount">
        {{ "Invalid Amount" | translate }}
      </div>
    </div>
    <!-- <div>
      <label for='paymentType'>{{'Payment Method' | translate }}</label>
      <ng-select [items]="paymentMethods"
                    [searchable]='false'
                    [clearable]='false'
                    class="custom-selector"
                    formControlName="paymentMethod"
                    placeholder='{{"Select one" | translate }}'>
          </ng-select>
      <div class="text-red-500 text-sm ms-2" *ngIf='refundFormErrors.paymentMethod'>{{refundFormErrors.paymentMethod}}</div>
      </div> -->
    <div class="flex flex-col gap-[10px]">
      <label class="Poppins-Medium text-base" for="paymentAccount">{{
        "Customer Account" | translate
      }}</label>
      <input
        formControlName="paymentAccount"
        [value]="
          invoiceData?.customerDetails?.customerId?.accountDetails?.accountName
        "
        id="memo"
        type="text"
        class="w-full bg-input rounded-lg border-0 h-11 me-2"
        disabled
      />
      <div
        class="text-red-500 text-sm ms-2"
        *ngIf="refundFormErrors.paymentAccount"
      >
        {{ refundFormErrors.paymentAccount }}
      </div>
    </div>
    <div class="flex flex-col gap-[10px]">
      <label class="Poppins-Medium text-base" for="memo">{{
        "Memo" | translate
      }}</label>
      <input formControlName="memo" id="memo" type="text" class="new-input" />
    </div>
  </div>
  <div footer class="flex gap-x-2">
    <app-button
      buttonText="{{ 'Record' | translate }}"
      type="primary"
      rounded="true"
      (click)="generateRefundPayment()"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      type="third"
      rounded="true"
      (click)="cancelRefund()"
    ></app-button>
  </div>
</app-modal-container>

<ngx-spinner
  name="view"
  bdColor="rgba(138,170,172,0.49)"
  size="medium"
  color="#0C4D71"
  type="ball-clip-rotate"
  [fullScreen]="false"
></ngx-spinner>
