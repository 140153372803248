import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
})
export class ChipsComponent implements OnInit {
  @Input() label!: string;
  @Input() idx: number;
  @Input() index: number;
  @Output() handleClick = new EventEmitter<Record<string, number>>();

  constructor() {}

  ngOnInit(): void {}

  onClick(): void {
    this.handleClick.emit({ index: this.index, idx: this.idx });
  }
}
