<span
  id="badge-dismiss-default"
  class="inline-flex items-center h-[44px] px-2 me-2 Poppins-medium bg-[#F0F5FF] rounded-md"
>
  {{ label }}
  <!-- data-dismiss-target="#badge-dismiss-default" -->
  <button
    type="button"
    class="inline-flex items-center p-1 ms-2 text-blue-400 bg-transparent rounded-sm hover:bg-[#F0F5FF] hover:text-blue-900 dark:hover:bg-blue-800 dark:hover:text-blue-300"
    aria-label="Remove"
    (click)="onClick()"
  >
    <svg
      class="w-3 h-3"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
      />
    </svg>
    <span class="sr-only">Remove badge</span>
  </button>
</span>
