<div
  class="timer-container relative inline-block h-10 w-32 p-2 rounded-full bg-gray-100 overflow-hidden transition-all duration-150 ease-in-out shadow-lg"
>
  <div
    class="svg absolute transition-opacity duration-150 ease-in-out flex items-center gap-2"
  >
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        viewBox="0 -960 960 960"
        fill="#000000"
      >
        <path
          d="M360-840v-80h240v80H360Zm80 440h80v-240h-80v240Zm40 320q-74 0-139.5-28.5T226-186q-49-49-77.5-114.5T120-440q0-74 28.5-139.5T226-694q49-49 114.5-77.5T480-800q62 0 119 20t107 58l56-56 56 56-56 56q38 50 58 107t20 119q0 74-28.5 139.5T734-186q-49 49-114.5 77.5T480-80Zm0-80q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-280Z"
        />
      </svg>
    </div>
    <div class="text-base Poppins-SemiBold mt-1">
      {{ totalWork | timeFormat : true | empty }}
    </div>
  </div>
  <div
    class="text hidden opacity-0 transition-opacity duration-150 ease-in-out grid grid-cols-5 gap-2"
  >
    <div class="col-span-2">
      <div class="text-base Poppins-SemiBold">Project:</div>
      <div class="text-base Poppins-SemiBold">Customer:</div>
      <div class="text-base Poppins-SemiBold">Timer:</div>
    </div>
    <div class="col-span-3">
      <div class="text-base">{{ timer.project | empty }}</div>
      <div class="text-base">{{ timer.customer | empty }}</div>
      <div class="text-base">{{ totalWork | timeFormat : true | empty }}</div>
    </div>
  </div>
</div>
