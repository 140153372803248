<div class="w-full p-8">
  <div class="bg-white px-14 py-11 rounded-lg flex flex-col space-y-4">
    <div>
      <div class="flex items-center justify-between">
        <div
          class="flex gap-x-2"
          [formGroup]="filterForm"
        >
          <div class="">
            <label class="Poppins-Medium">{{
              "Select Vendor" | translate
            }}</label>
            <ng-select
              [items]="vendors"
              class="new-dropdown"
              bindLabel="vendorName"
              bindValue="_id"
              formControlName="vendorId"
              placeholder="{{ 'Type Vendor Name' | translate }}"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div class="px-2 py-1">
                  <div
                    class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                  >
                    {{ item.vendorName }}
                  </div>
                </div>
              </ng-template>
            </ng-select>
          </div>
          <div class="self-end">
            <label class="Poppins-Medium">{{
              "Select Projects" | translate
            }}</label>
            <ng-select
              [items]="projects"
              class="new-dropdown"
              bindLabel="projectName"
              bindValue="_id"
              formControlName="projectId"
              placeholder="{{ 'Type Project Name' | translate }}"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div class="px-2 py-1">
                  <div
                    class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                  >
                    {{ item.projectName }}
                  </div>
                </div>
              </ng-template>
            </ng-select>
          </div>
          <div class="self-end">
            <label class="Poppins-Medium" for="startDate">{{
              "From" | translate
            }}</label>
            <input
              formControlName="fromDate"
              type="date"
              class="new-date"
            />
            <div *ngIf="formErrors.fromDate" class="text-red-500 ms-4">
              {{ formErrors.fromDate }}
            </div>
          </div>
          <div class="self-end">
            <label class="Poppins-Medium" for="endDate">{{
              "To" | translate
            }}</label>
            <input
              formControlName="endDate"
              type="date"
              class="new-date"
            />
            <div *ngIf="formErrors.endDate" class="text-red-500 ms-4">
              {{ formErrors.endDate }}
            </div>
          </div>
          <div class="self-end">
            <app-button
              buttonText="{{ 'Filter Bills' | translate }}"
              size="large"
              type="primary"
              (handleClick)="filterData()"
            ></app-button>
          </div>
        </div>
        <app-button
          class="self-end"
          appSubscriptionCheck
          featureToCheck="transactionLimit"
          appAccessControl
          moduleType="purchase"
          accessType="edit"
          routerLink="create-bill"
          type="primary"
          size="large"
          buttonText="{{ 'Create a Bill' | translate }}"
        ></app-button>
      </div>
      <div class="mt-4">
        <app-table-container
          [tableHeadings]="tableHeadings"
          [tableData]="tableData"
          [keys]="tableKeys"
          [actionTemplate]="actionTemplate"
        >
          <ng-template let-item #actionTemplate>
            <td
              appAccessControl
              moduleType="purchase"
              accessType="edit"
              class="p-2 text-center"
            >
              <app-dropdown #dropdown>
                <div
                  button
                  (click)="dropdown.open = true"
                  (clickOutside)="dropdown.open = false"
                >
                  <div
                    class="h-8 w-8 rounded-lg flex justify-center items-center border-2 border-[#DCDCDC]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
                <div options class="new-shadow p-1">
                  <div
                    (click)="viewBill(item._id)"
                    class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] text-left"
                  >
                    {{ "View" | translate }}
                  </div>
                  <div
                    (click)="editBill(item._id)"
                    class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] text-left"
                  >
                    {{ "Edit" | translate }}
                  </div>
                  <div
                    (click)="openRecordPayment(item)"
                    class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] text-left"
                  >
                    {{ "Record Payment" | translate }}
                  </div>
                </div>
              </app-dropdown>
            </td>
          </ng-template>
        </app-table-container>
      </div>
    </div>
  </div>
</div>

<app-modal-container [isOpen]="deleteOpenModal">
  <div header class="border-b pb-2 Poppins-Medium">
    {{ "Delete Bills" | translate }}
  </div>
  <div content>{{ "Are you sure?" | translate }}</div>
  <div footer class="flex items-center space-x-2">
    <app-button
      buttonText="{{ 'Delete' | translate }}"
      type="danger"
      rounded="true"
      (handleClick)="deleteBillslist()"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      type="third"
      rounded="true"
      (handleClick)="deleteOpenModal = false"
    ></app-button>
  </div>
</app-modal-container>

<record-payment
  type="bill"
  [isOpen]="recordPaymentModal"
  [amountDue]="amountDue"
  [minDate]="minDate"
  (closeModal)="recordPaymentModal = false"
  (paymentData)="recordPayment($event)"
></record-payment>
