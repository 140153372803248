import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DirectivesModule } from '../directives/directives.module';
import { CurrencywithspacePipe } from '../pipes/currencywithspace.pipe';
import { EmptyPipe } from '../pipes/empty.pipe';
import { FloorPipe } from '../pipes/floor.pipe';
import { PadStartPipe } from '../pipes/pad-start.pipe';
import { PipesModule } from '../pipes/pipes.module';
import { TimeFormatPipe } from '../pipes/time-format.pipe';
import { ChipsComponent } from './components/chips/chips.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { GlobalTimerComponent } from './components/global-timer/global-timer.component';
import { HeaderComponent } from './components/header/header.component';
import { ImageCropperComponent } from './components/modals/image-cropper/image-cropper.component';
import { ModalContainerComponent } from './components/modals/modal-container/modal-container.component';
import { RecordPaymentComponent } from './components/modals/record-payment/record-payment.component';
import { UserSettingsComponent } from './components/modals/user-settings/user-settings.component';
import { SendToSubComponent } from './components/send-to-sub/send-to-sub.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SubmitButtonComponent } from './components/submit-button/submit-button.component';
import { TableContainerComponent } from './components/tables/table-container/table-container.component';
import { TabComponent } from './components/tabs/tab.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { ButtonComponent } from './components/utils/button/button.component';
import { DropdownComponent } from './components/utils/dropdown/dropdown.component';
import { TooltipComponent } from './components/utils/tooltip/tooltip.component';
import { CustomerStatementComponent } from './documents/customer-statement/customer-statement.component';
import { DocumentContainerComponent } from './documents/document-container.component';
import { TemplateFiveComponent } from './documents/invoices/template-five/template-five.component';
import { TemplateFourComponent } from './documents/invoices/template-four/template-four.component';
import { TemplateOneComponent } from './documents/invoices/template-one/template-one.component';
import { TemplateSevenComponent } from './documents/invoices/template-seven/template-seven.component';
import { TemplateSixComponent } from './documents/invoices/template-six/template-six.component';
import { TemplateThreeComponent } from './documents/invoices/template-three/template-three.component';
import { TemplateTwoComponent } from './documents/invoices/template-two/template-two.component';
import { ReceiptComponent } from './documents/receipt/receipt.component';

@NgModule({
  declarations: [
    SidebarComponent,
    ButtonComponent,
    DropdownComponent,
    ModalContainerComponent,
    UserSettingsComponent,
    ImageCropperComponent,
    TableContainerComponent,
    DocumentContainerComponent,
    TemplateOneComponent,
    TemplateTwoComponent,
    TemplateThreeComponent,
    RecordPaymentComponent,
    TabsComponent,
    TabComponent,
    TooltipComponent,
    CustomerStatementComponent,
    ReceiptComponent,
    SendToSubComponent,
    FileUploadComponent,
    TemplateFourComponent,
    TemplateFiveComponent,
    TemplateSixComponent,
    TemplateSevenComponent,
    HeaderComponent,
    SubmitButtonComponent,
    FloorPipe,
    PadStartPipe,
    TimeFormatPipe,
    CurrencywithspacePipe,
    GlobalTimerComponent,
    ChipsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ImageCropperModule,
    NgxSpinnerModule,
    NgSelectModule,
    ClickOutsideModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    TranslateModule,
    DirectivesModule,
    PipesModule,
  ],
  exports: [
    SidebarComponent,
    ButtonComponent,
    DropdownComponent,
    ModalContainerComponent,
    UserSettingsComponent,
    ImageCropperComponent,
    TableContainerComponent,
    TemplateOneComponent,
    TemplateTwoComponent,
    TemplateThreeComponent,
    RecordPaymentComponent,
    TabsComponent,
    TabComponent,
    TooltipComponent,
    CustomerStatementComponent,
    SendToSubComponent,
    FileUploadComponent,
    EmptyPipe,
    FloorPipe,
    PadStartPipe,
    TimeFormatPipe,
    CurrencywithspacePipe,
    TemplateFourComponent,
    TemplateFiveComponent,
    TemplateSixComponent,
    TemplateSevenComponent,
    HeaderComponent,
    SubmitButtonComponent,
    TemplateSevenComponent,
    GlobalTimerComponent,
    ChipsComponent,
  ],
})
export class SharedModule {}
