<div class="w-full p-8">
  <div class="bg-white px-14 py-11 rounded-lg flex flex-col space-y-4">
    <div>
      <div class="flex items-center justify-end">
        <div class="">
          <app-button
            appAccessControl
            moduleType="purchase"
            accessType="edit"
            routerLink="create-purchase-order"
            type="primary"
            size="large"
            buttonText="{{ 'Create a Purchase Order' | translate }}"
          ></app-button>
        </div>
      </div>
      <div class="mt-4">
        <app-table-container
          [tableHeadings]="tableHeadings"
          [tableData]="tableData"
          [keys]="tableKeys"
          [actionTemplate]="actionTemplate"
        >
          <ng-template let-item #actionTemplate>
            <td
              appAccessControl
              moduleType="purchase"
              accessType="edit"
              align="right"
              class="p-2 last:pe-10 text-center"
            >
            <app-dropdown #dropdown [isFromSettings]="true">
              <div
                button
                (click)="dropdown.open = true"
                (clickOutside)="dropdown.open = false"
              >
                <div
                  class="h-8 w-8 rounded-lg flex justify-center items-center border-2 border-[#DCDCDC]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
              <div options class="new-shadow p-1">
                <div
                  (click)="viewOrder(item._id)"
                  class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] text-left"
                >
                  {{ "View" | translate }}
                </div>
                <div
                  (click)="editOrder(item._id)"
                  class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] text-left"
                >
                  {{ "Edit" | translate }}
                </div>
                <div
                  (click)="deletePurchaseOrder(item._id)"
                  class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] text-left"
                >
                  {{ "Delete" | translate }}
                </div>
              </div>
            </app-dropdown>
              <!-- <app-dropdown #dropdown>
                <div
                  button
                  (click)="dropdown.open = true"
                  (clickOutside)="dropdown.open = false"
                >
                  <div
                    class="h-8 w-8 rounded-full flex justify-center items-center bg-gray-50 border border-gray-200"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4 m-auto leading-8 text-center"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
                <div options>
                  <span
                    (click)="viewOrder(item._id)"
                    class="cursor-pointer block px-4 py-2 text-sm Poppins-Bold hover:bg-gray-50 text-center rounded-t-xl"
                    >{{ "View" | translate }}</span
                  >
                  <span
                    (click)="editOrder(item._id)"
                    class="cursor-pointer block px-4 py-2 text-sm Poppins-Bold hover:bg-gray-50 text-center rounded-t-xl"
                    >{{ "Edit" | translate }}</span
                  >
                  <span
                    (click)="deletePurchaseOrder(item._id)"
                    class="cursor-pointer block px-4 py-2 text-sm Poppins-Bold hover:bg-gray-50 text-center rounded-b-xl"
                    >{{ "Delete" | translate }}</span
                  >
                </div>
              </app-dropdown> -->
            </td>
          </ng-template>
        </app-table-container>
      </div>
    </div>
  </div>
</div>

<app-modal-container [isOpen]="deleteOpenModal">
  <div header class="border-b pb-2 Poppins-Bold">
    {{ "Delete Purchase & Order" | translate }}
  </div>
  <div content>{{ "Are you sure?" | translate }}</div>
  <div footer class="flex items-center gap-x-2">
    <app-button
      buttonText="{{ 'Delete' | translate }}"
      type="danger"
      (handleClick)="deletePurchaseList()"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      (handleClick)="deleteOpenModal = false"
    ></app-button>
  </div>
</app-modal-container>
