<table class="min-w-full" style="border-spacing: 0 1rem">
  <thead>
    <tr class="h-12 mb-4">
      <td
        class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 px-3 text-left last:text-center"
        *ngFor="let item of tableHeadings"
      >
        {{ item | translate }}
      </td>
    </tr>
  </thead>
  <tbody>
    <tr
      class=""
      *ngFor="
        let item of tableData
          | paginate : { itemsPerPage: 20, currentPage: page }
      "
    >
      <td
        [ngClass]="{ 'last:text-center': last && !actionTemplate }"
        class="px-4 py-2"
        *ngFor="let key of keys; let last = last"
      >
        {{ item[key] | empty }}
      </td>
      <ng-container
        *ngTemplateOutlet="actionTemplate; context: { $implicit: item }"
      ></ng-container>
    </tr>
    <tr *ngIf="tableData?.length === 0">
      <td [colSpan]="tableHeadings.length" class="text-center">
        {{ emptyTableMessage || "No Data Found" }}
      </td>
    </tr>
  </tbody>
</table>
<pagination-controls
  autoHide="true"
  class="new-pagination mt-4"
  (pageChange)="page = $event"
  previousLabel=""
  nextLabel=""
></pagination-controls>
